











































import {Vue, Component, Model, VModel, Prop} from 'vue-property-decorator';
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import StateIdInternalDTO from "@/dto/person/StateIdDTO";
import PortalAddress from "@/components/profile/branches/PortalAddress.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import {StateIDSubtype} from "@/constants/PersonConstants";
import {namespace} from "vuex-class";
import PersonService from "@/services/PersonService";
import {processError} from "@/utils/ComponentUtils";
import StateIdDTO from "@/dto/person/StateIdDTO";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import {ExpirableDocumentType} from "@/constants/DocumentConstants";
import Notifications from "@/state/Notifications";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";

const AppModule = namespace("App");

@Component({
  computed: {
	  SelectOption() {
		  return SelectOption
	  },
    StateIDInternalSubtype() {
      return StateIDSubtype
    }
  },
  components: {PortalSelect, PortalCollapse, PortalDate, PortalInput, PortalAddress, SingleFileHolder}
})
export default class StateId extends Vue {

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  @Prop()
  stateId!: StateIdDTO
  stateIdInternal = this.stateId;

  editMode = false

  @Prop()
  onDelete!: () => void;

  edit(){
    this.startLoading();
    PersonService.editStateId(this.stateIdInternal).then(
        ok => {
          this.editMode = false;
          this.stopLoading();
          this.$emit('update:stateId', this.stateIdInternal);
        },
        err => {
          processError(err, this);
          this.stopLoading();
        }
    )

  }

  askDelete(){
    this.$modal.show(
        DeleteConfirmation,
        {
          onDelete: () => this.delete(),
          targetName: this.targetName
        }
    )
  }

  delete(){
    this.startLoading();
    PersonService.deletePersonalDocument(this.stateId.personId, ExpirableDocumentType.STATE_ID, this.stateId.id).then(
        ok => {
          this.stopLoading();
          Notifications.info(`${this.targetName} has successfully been deleted`);
          this.onDelete()
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }

  get targetName(): string {
    switch(this.stateId.subtype){
      case StateIDSubtype.ID: return "State ID";
      case StateIDSubtype.DRIVER_LICENSE: return "Driver license";
      case StateIDSubtype.LEARNER_PERMIT: return "Learner permit";
      default: return "document"
    }
  }

}
